











































import { Vue, Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component({})
export default class LoginComponent extends Vue {
  user = ''
  pass = ''

  message = 'message'
  alert = false

  @Action('login') readonly login
  loginTest() {
    // console.log(new Date())
    this.alert = false
    const loginButton = document.getElementById('loginButton')
    if (loginButton == null) {
      throw new Error('unable to find element')
    }
    loginButton.setAttribute('disabled', 'true')
    this.login({ user: this.user, pass: this.pass })
      .then(() => {
        // console.log(new Date())
      })
      .catch((err: Error) => {
        // console.log(err.name)
        // console.log(err.message)
        switch (err.name) {
          case 'NetworkingError':
            this.message = 'ネットワーク接続エラー'
            break
          case 'InvalidParameterException':
            this.message = '入力が無効です'
            break
          case 'NotAuthorizedException':
          case 'UserNotFoundException':
            this.message = 'ユーザ名またはパスワードが違います'
            break

          default:
            this.message = 'エラー ' + err.name + ':' + err.message
        }
        loginButton.removeAttribute('disabled')
        this.alert = true
      })
  }
  close() {
    this.alert = false
  }
}
