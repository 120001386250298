import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { RootState } from '@/store/types'
// import { sellerInfoModule } from './sellerInfo'
Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  actions,
  // modules: { sellerInfo: sellerInfoModule }
})
