import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import CognitoAuth from '@/api/CognitoAuth'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import state from './state'

const getters: GetterTree<RootState, RootState> = {
  settings(state) {
    return state.settings
  },
  cognitoUser(state): CognitoUser | null {
    return state.cognitoUser
  },
  session(state): null | CognitoUserSession {
    return state.session
  },
  accountInfo(state) {
    if (!state.session || typeof state.session.getIdToken !== 'function') {
      return null
    }
    return CognitoAuth.parseToken(state.session.getIdToken().getJwtToken())
  },
  usdiffQuery(state) {
    return state.usdiffQuery
  },
  usdiffResult(state) {
    return state.usdiffResult
  },
  audiffQuery(state) {
    return state.audiffQuery
  },
  audiffResult(state) {
    return state.audiffResult
  },
  listUsersQuery(state) {
    return state.listUsersQuery
  },
  listUsersResult(state) {
    return state.listUsersResult
  },
  listGroupUsersQuery(state) {
    return state.listGroupUsersQuery
  },
  listGroupUsersResult(state) {
    return state.listGroupUsersResult
  },
  // userDetail(state) {
  //   return state.userDetail
  // },
  // showUserDetail(state) {
  //   return state.showUserDetail
  // },
  listFbaItemsQuery(state) {
    return state.listFbaItemsQuery
  },
  listFbaItemsResult(state) {
    return state.listFbaItemsResult
  },
  listMfnSettingsQuery(state) {
    return state.listMfnSettingsQuery
  },
  listMfnSettingsResult(state) {
    return state.listMfnSettingsResult
  },
  listOrderItemsQuery(state) {
    return state.listOrderItemsQuery
  },
  listOrderItemsResult(state) {
    return state.listOrderItemsResult
  },
  isAddressExtensionAvailable(_state) {
    return false
    // return state.addressExtensionAvailable
  },
  listSellerInfoQuery(state) {
    return state.listSellerInfoQuery
  },
  listSellerInfoResult(state) {
    return state.listSellerInfoResult
  },
  listMfnUploadStatusQuery(state) {
    return state.listMfnUploadStatusQuery
  },
  listMfnUploadStatusResult(state) {
    return state.listMfnUploadStatusResult
  },
  getSurcharge(stte) {
    return state.surcharge
  }
}
export default getters
