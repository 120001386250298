import { Country, CountryPair } from './CountryTypes'

export default class CountryMgr {
  public static getAvailableCountryPairs(): string[] {
    return ['JPUS', 'JPAU']
  }
  public static getAvailableCountries(): string[] {
    return ['JP', 'US', 'AU']
  }
  public static getCountryPair(countryPair: string): CountryPair {
    if (this.getAvailableCountryPairs().indexOf(countryPair) < 0) throw new Error('Invalid Pair' + countryPair)
    const src = countryPair.substr(0, 2)
    const dst = countryPair.substr(2, 2)
    return {
      name: countryPair,
      src: this.getCountry(src),
      dst: this.getCountry(dst),
    }
  }
  public static getCountry(countryCode: string): Country {
    switch (countryCode) {
      case 'JP':
        return {
          code: 'JP',
          name: '日本',
          shortName: '日本',
          veryShortName: '日',
          currencyCode: 'JPY',
          currencyFractionDigits: 0,
          marketplaceDomain: 'amazon.co.jp',
        }
      case 'US':
        return {
          code: 'US',
          name: 'アメリカ',
          shortName: '米国',
          veryShortName: '米',
          currencyCode: 'USD',
          currencyFractionDigits: 2,
          marketplaceDomain: 'amazon.com',
        }
      case 'AU':
        return {
          code: 'AU',
          name: 'オーストラリア',
          shortName: '豪州',
          veryShortName: '豪',
          currencyCode: 'AUD',
          currencyFractionDigits: 2,
          marketplaceDomain: 'amazon.com.au',
        }
      default:
        throw new Error('国コードエラー:' + countryCode)
    }
  }
}
