import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  AuthenticationDetails,
  ICognitoUserData,
  ICognitoUserPoolData,
  CognitoUserAttribute,
  ICognitoStorage,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js'
const apigClientFactory = require('aws-api-gateway-client').default

class ICognitoStrageWrapper implements ICognitoStorage {
  private storage: Storage
  constructor(storage: Storage) {
    this.storage = storage
  }
  public clear() {
    this.storage.clear()
  }
  public setItem(key: string, value: string) {
    this.storage.setItem(key, value)
  }
  public getItem(key: string): string {
    const value = this.storage.getItem(key)
    if (value) {
      return value
    }
    return '-'
  }
  public removeItem(key: string) {
    this.storage.removeItem(key)
  }
}

class CognitoAuth {
  private readonly _userPool: CognitoUserPool
  private _apigClient: any

  constructor() {
    const sStorage: ICognitoStorage = new ICognitoStrageWrapper(sessionStorage)
    const data: ICognitoUserPoolData = {
      UserPoolId: process.env.VUE_APP_userPoolId || 'undefined',
      ClientId: process.env.VUE_APP_loginClientId || 'undefined',
      Storage: sStorage,
    }
    this._userPool = new CognitoUserPool(data)
    const apigClientConfig = {
      invokeUrl: process.env.VUE_APP_invokeUrl || 'undefined',
      service: 'execute-api',
    }
    this._apigClient = apigClientFactory.newClient(apigClientConfig)
  }

  public getUser = (): CognitoUser | null => {
    return this._userPool.getCurrentUser()
  }

  public getSession = (): Promise<CognitoUserSession | Error> => {
    const cognitoUser = this.getUser()
    return new Promise((resolve, reject) => {
      if (!cognitoUser) {
        reject(new Error('not logged in'))
      } else {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err)
          }
          resolve(session)
        })
      }
    })
  }

  public signup = (user: string, pass: string, attrs: CognitoUserAttribute[]) => {
    return new Promise((resolve, reject) => {
      const validationData: CognitoUserAttribute[] = []
      this._userPool.signUp(user, pass, attrs, validationData, function (err, result) {
        if (err || !result) {
          reject(err)
        } else {
          resolve(result.user)
        }
      })
    })
  }

  public login = (user: string, pass: string): Promise<any | CognitoUser> => {
    const authDetails: AuthenticationDetails = new AuthenticationDetails({
      Username: user,
      Password: pass,
    })
    const sStorage: ICognitoStorage = new ICognitoStrageWrapper(sessionStorage)
    const userData: ICognitoUserData = {
      Username: user,
      Pool: this._userPool,
      Storage: sStorage,
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      const callback = {
        onSuccess: (session?: CognitoUserSession) => {
          if (session != null) resolve(cognitoUser)
          else reject(new Error('noSessionData'))
        },
        onFailure: err => {
          reject(err)
        },
        newPasswordRequired: () => {
          reject(new Error('requiredNewPassword'))
        },
      }
      cognitoUser.authenticateUser(authDetails, callback)
    })
  }

  public logout = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const cognitoUser = this._userPool.getCurrentUser()
      if (cognitoUser != null) {
        cognitoUser.signOut()
        resolve()
        // AWS.config.credentials.clearCachedId();
      } else {
        reject()
      }
    })
  }

  public forgotPassword = (user: string): Promise<any> => {
    const userData: ICognitoUserData = {
      Username: user,
      Pool: this._userPool,
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve(cognitoUser)
        },
        onFailure: err => {
          reject(err)
        },
      })
    })
  }

  public confirmPassword = (cognitoUser: CognitoUser, confirmationCode: string, newPassword: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(confirmationCode, newPassword, {
        onSuccess: () => {
          resolve()
        },
        onFailure: err => {
          reject(err)
        },
      })
    })
  }

  public changePassword = (cognitoUser: CognitoUser, oldPassword: string, newPassword: string) => {
    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  public invokeApi = (
    params: any,
    pathTemplate: any,
    method: any,
    additionalParams: any,
    body: any,
    anonymous = false
  ): Promise<any> => {
    if (anonymous) {
      return new Promise((resolve, reject) => {
        try {
          const ret = this._apigClient.invokeApi(params, pathTemplate, method, additionalParams, body)
          resolve(ret)
        } catch (err) {
          reject(err)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        this.getSession()
          .then((session: CognitoUserSession | Error) => {
            if (session instanceof Error) {
              reject()
              return
            }
            additionalParams['headers'] = {
              Authorization: session.getIdToken().getJwtToken(),
            }
            try {
              const ret = this._apigClient.invokeApi(params, pathTemplate, method, additionalParams, body)
              resolve(ret)
            } catch (err) {
              reject(err)
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  }
  public static getIdToken = (session: CognitoUserSession) => {
    if (!session) {
      return null
    }
    session.getIdToken().getJwtToken()
  }
  public static parseToken(token: string): any {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'))
  }
  public setAttributes = (attrs: { Name: string; Value: string }[]): Promise<string | Error> => {
    const user = this._userPool.getCurrentUser()
    if (!user) {
      return Promise.reject()
    }

    const sendAttrs: ICognitoUserAttributeData[] = []
    attrs.forEach(attr => {
      sendAttrs.push({ Name: attr['Name'], Value: attr['Value'] })
    })

    return new Promise((resolve, reject) => {
      user.getSession(() => {
        user.updateAttributes(sendAttrs, (err, result) => {
          if (err || !result) {
            reject(err)
            return
          }
          resolve(result)

          // 3秒後にtoken更新
          setTimeout(() => {
            const session = user.getSignInUserSession()
            if (!session) return reject()
            user.refreshSession(session.getRefreshToken(), (err, result) => {
              if (err) {
                // console.log('failed to refresh session')
              }
              // console.log('successed to refresh session')
            })
          }, 3000)
          return
        })
      })
    })
  }
  public getAttributes = (keys: string[]): Promise<any> => {
    const user = this._userPool.getCurrentUser()
    if (!user) {
      return Promise.reject()
    }

    return new Promise((resolve, reject) => {
      user.getSession(() => {
        user.getUserAttributes((err, result) => {
          if (err || !result) {
            reject(err)
            return
          }
          resolve(result.filter(attr => keys.indexOf(attr.getName()) >= 0))
          return
        })
      })
    })
  }
}

export default CognitoAuth
