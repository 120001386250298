import { MutationTree } from 'vuex'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import CognitoAuth from '@/api/CognitoAuth'
import { RootState } from '@/store/types'

const mutations: MutationTree<RootState> = {
  SET_COGNITOAUTH(state, cognitoAuth: CognitoAuth) {
    state.cognitoAuth = cognitoAuth
  },
  SET_COGNITOUSER(state, cognitoUser: CognitoUser) {
    state.cognitoUser = cognitoUser
  },
  SET_SESSION(state, session: CognitoUserSession) {
    state.session = session
  },
  SET_SETTINGS(state, o: { key: string; value: object }) {
    state.settings[o.key] = o.value
  },
  SET_USDIFF_QUERY(state, usdiffQuery: any) {
    state.usdiffQuery = usdiffQuery
  },
  SET_USDIFF_RESULT(state, usdiffResult: any) {
    state.usdiffResult = usdiffResult
  },
  SET_AUDIFF_QUERY(state, audiffQuery: any) {
    state.audiffQuery = audiffQuery
  },
  SET_AUDIFF_RESULT(state, audiffResult: any) {
    state.audiffResult = audiffResult
  },
  SET_LIST_USERS_QUERY(state, listUsersQuery: any) {
    state.listUsersQuery = listUsersQuery
  },
  SET_LIST_USERS_RESULT(state, listUsersResult: any) {
    state.listUsersResult = listUsersResult
  },
  SET_ACCOUNT_HEALTH_QUERY(state, listAccountHealthQuery: any) {
    state.listAccountHealthQuery = listAccountHealthQuery
  },
  SET_ACCOUNT_HEALTH_RESULT(state, listAccountHealthResult: any) {
    state.listAccountHealthResult = listAccountHealthResult
  },
  SET_LIST_SELLER_INFO_QUERY(state, listSellerInfoQuery: any) {
    state.listSellerInfoQuery = listSellerInfoQuery
  },
  SET_LIST_SELLER_INFO_RESULT(state, listSellerInfoResult: any) {
    state.listSellerInfoResult = listSellerInfoResult
  },
  SET_LIST_SELLER_INFO_QUERY2(state, listSellerInfoQuery2: any) {
    state.listSellerInfoQuery2 = listSellerInfoQuery2
  },
  SET_LIST_SELLER_INFO_RESULT2(state, listSellerInfoResult2: any) {
    state.listSellerInfoResult2 = listSellerInfoResult2
  },
  UPDATE_LIST_USERS_RESULT(state, o: { username: string; action: string }) {
    switch (o.action) {
      case 'delete':
        state.listUsersResult.data.users = state.listUsersResult.data.users.filter(user => user.Username !== o.username)
        break
      case 'approve':
        state.listUsersResult.data.users = state.listUsersResult.data.users.map(user => {
          if (user['Username'] === o.username) {
            user['UserStatus'] = 'CONFIRMED'
          }
          return user
        })
        break
      case 'enable':
        state.listUsersResult.data.users = state.listUsersResult.data.users.map(user => {
          if (user['Username'] === o.username) {
            user['Enabled'] = true
          }
          return user
        })
        break
      case 'disable':
        state.listUsersResult.data.users = state.listUsersResult.data.users.map(user => {
          if (user['Username'] === o.username) {
            user['Enabled'] = false
          }
          return user
        })
        break
      default:
        break
    }
  },
  SET_LIST_GROUP_USERS_QUERY(state, listGroupUsersQuery: any) {
    state.listGroupUsersQuery = listGroupUsersQuery
  },
  SET_LIST_GROUP_USERS_RESULT(state, listGroupUsersResult: any) {
    state.listGroupUsersResult = listGroupUsersResult
  },
  UPDATE_LIST_GROUP_USERS_RESULT(state, o: { username: string; action: string; group: string }) {
    switch (o.action) {
      case 'delete':
        state.listGroupUsersResult.data.users = state.listGroupUsersResult.data.users.filter(
          user => user.Username !== o.username
        )
        break
      default:
        break
    }
  },
  // SET_USER_DETAIL(state, userDetail: { UserAttributes: any[]; Groups: any[]; userid: string }) {
  //   state.userDetail = userDetail
  // },
  // SET_SHOW_USER_DETAIL(state, show: boolean) {
  //   state.showUserDetail = show
  // },
  SET_USDIFF_CHECKED(state, o: { asin_index: number; checked: boolean }) {
    state.usdiffResult[o.asin_index]['checked'] = o.checked
  },
  SET_AUDIFF_CHECKED(state, o: { asin_index: number; checked: boolean }) {
    state.audiffResult[o.asin_index]['checked'] = o.checked
  },
  SET_LIST_FBA_ITEMS_QUERY(state, listFbaItemsQuery: any) {
    state.listFbaItemsQuery = listFbaItemsQuery
  },
  SET_LIST_FBA_ITEMS_RESULT(state, listFbaItemsResult: any) {
    state.listFbaItemsResult = listFbaItemsResult
  },
  SET_LIST_MFN_SETTINGS_QUERY(state, listMfnSettingsQuery: any) {
    state.listMfnSettingsQuery = listMfnSettingsQuery
  },
  SET_LIST_MFN_SETTINGS_RESULT(state, listMfnSettingsResult: any) {
    state.listMfnSettingsResult = listMfnSettingsResult
  },
  SET_FBAUS_CHECKED(state, o: { sku_index: number; checked: boolean }) {
    state.listFbaItemsResult[o.sku_index]['checked'] = o.checked
  },
  SET_MFN_ASIN_UPLOAD_QUERY(state, mfnAsinUploadQuery: any) {
    state.mfnAsinUploadQuery = mfnAsinUploadQuery
  },
  SET_MFN_ASIN_UPLOAD_RESULT(state, mfnAsinUploadResult: any) {
    state.mfnAsinUploadResult = mfnAsinUploadResult
  },
  SET_LIST_MFN_UPLOAD_STATUS_QUERY(state, listMfnUploadStatusQuery: any) {
    state.listMfnUploadStatusQuery = listMfnUploadStatusQuery
  },
  SET_LIST_MFN_UPLOAD_STATUS_RESULT(state, listMfnUploadStatusResult: any) {
    state.listMfnUploadStatusResult = listMfnUploadStatusResult
  },
  UPDATE_FBA_ITEMS_RESULT(state, o: { action: string; skus: [object]; result: any }) {
    switch (o.action) {
      case 'PUT':
        state.listFbaItemsResult = state.listFbaItemsResult.map(item => {
          o.skus.some(putItem => {
            if (item['sku'] === putItem['sku']) {
              Object.keys(putItem).forEach(key => {
                item[key] = putItem[key]
              })
              return true
            }
            return false
          })
          return item
        })
        break
      case 'DELETE':
        state.listFbaItemsResult = state.listFbaItemsResult.filter(item => {
          return o.skus.filter(delItem => delItem['sku'] === item['sku']).length === 0
        })
        break
    }
  },
  UPDATE_MFN_SETTINGS_RESULT(state, o: { action: string; group_id: [object]; result: any }) {
    switch (o.action) {
      case 'PUT':
        state.listMfnSettingsResult = state.listMfnSettingsResult.map(item => {
          o.group_id.some(putItem => {
            if (item['group_id'] === putItem['group_id']) {
              Object.keys(putItem).forEach(key => {
                item[key] = putItem[key]
              })
              return true
            }
            return false
          })
          return item
        })
        break
      case 'DELETE':
        state.listMfnSettingsResult = state.listMfnSettingsResult.filter(item => {
          return o.group_id.filter(delItem => delItem['group_id'] === item['group_id']).length === 0
        })
        break
    }
  },
  SET_LIST_ORDER_ITEMS_QUERY(state, listOrderItemsQuery: any) {
    state.listOrderItemsQuery = listOrderItemsQuery
  },
  SET_LIST_ORDER_ITEMS_RESULT(state, listOrderItemsResult: any) {
    state.listOrderItemsResult = listOrderItemsResult
  },
  SET_ORDER_CHECKED(state, o: { order_item_id_index: number; checked: boolean }) {
    state.listOrderItemsResult['items'][o.order_item_id_index]['checked'] = o.checked
  },
  SET_ADDRESS_EXTENSION_AVAILABLE(state, isAvailable: boolean) {
    state.addressExtensionAvailable = isAvailable
  },
  SET_SURCHARGE(state, surcharge: object[]) {
    state.surcharge = surcharge
  },
}
export default mutations
