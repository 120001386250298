







import { Vue, Component } from 'vue-property-decorator'
import NavbarComponent from './components/navbar/navbar.vue'
import FooterComponent from './components/footer/footer.vue'
@Component({
  name: 'app',
  components: {
    navbar: NavbarComponent,
    'omega-footer': FooterComponent,
  },
})
export default class Main extends Vue {}
