import { RootState, Setting } from '@/store/types'
import CognitoAuth from '@/api/CognitoAuth'

export const setting: Setting = {
  date: Date.now(),
}

const state: RootState = {
  cognitoAuth: new CognitoAuth(),
  cognitoUser: null,
  session: null,
  settings: [],
  usdiffQuery: { params: {}, additionalParams: {} },
  usdiffResult: [],
  audiffQuery: { params: {}, additionalParams: {} },
  audiffResult: [],
  mfnAsinUploadQuery: { params: {}, additionalParams: {} },
  mfnAsinUploadResult: [],
  listUsersQuery: { params: {}, additionalParams: {} },
  listUsersResult: [],
  listGroupUsersQuery: { params: {}, additionalParams: {} },
  listGroupUsersResult: [],
  // userDetail: {
  //   UserAttributes: [],
  //   Groups: [],
  //   userid: undefined
  // },
  // showUserDetail: false,
  listFbaItemsQuery: { params: {}, additionalParams: {} },
  listFbaItemsResult: [],
  listMfnSettingsQuery: { params: {}, additionalParams: {} },
  listMfnSettingsResult: [],
  listOrderItemsQuery: { params: {}, additionalParams: {} },
  listOrderItemsResult: { items: [], total: 0, page: 0 },
  addressExtensionAvailable: false,
  listSellerInfoQuery: { params: {}, additionalParams: {} },
  listSellerInfoResult: [],
  listSellerInfoQuery2: { params: {}, additionalParams: {} },
  listSellerInfoResult2: [],
  listAccountHealthQuery: { params: {}, additionalParams: {} },
  listAccountHealthResult: [],
  listMfnUploadStatusQuery: { params: {}, additionalParams: {} },
  listMfnUploadStatusResult: [],
  surcharge: []
}
export default state
