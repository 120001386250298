import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { CognitoUser } from 'amazon-cognito-identity-js'
import router from '@/router'

const actions: ActionTree<RootState, RootState> = {
  login({ commit, state }, { user, pass }: { user: string; pass: string }): Promise<any> {
    return state.cognitoAuth
      .login(user, pass)
      .then((cognitoUser: CognitoUser) => {
        commit('SET_COGNITOUSER', cognitoUser)
        commit('SET_SESSION', cognitoUser.getSignInUserSession)
        let next = '/home'
        try {
          const redirect = router.currentRoute.query.redirect
          if (typeof redirect === 'string') {
            next = redirect
            // console.log('redirect to ' + redirect)
          }
        } catch (err) {
          next = '/home'
        }

        router.push({ path: next })

        // ログイン時のunixtimeを保存する
        cognitoUser.updateAttributes(
          [{ Name: 'custom:lastLogin', Value: Math.floor(new Date().getTime() / 1000).toString() }],
          () => { }
        )

        return Promise.resolve(cognitoUser)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  logout({ commit, state }): Promise<any> {
    return state.cognitoAuth
      .logout()
      .then(() => {
        commit('SET_COGNITOUSER', null)
        commit('SET_SESSION', null)
        router.push({ path: '/login' })
        return Promise.resolve()
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  getSession({ commit, state }): null {
    state.cognitoAuth
      .getSession()
      .then(session => {
        commit('SET_SESSION', session)
      })
      .catch(err => {
        commit('SET_SESSION', null)
      })
    return null
  },
  signup({ commit, state }, { user, pass, attrs }): Promise<any> {
    return state.cognitoAuth.signup(user, pass, attrs)
  },
  forgotPassword({ commit, state }, { userid }): Promise<any> {
    return state.cognitoAuth.forgotPassword(userid).then((cognitoUser: CognitoUser) => {
      commit('SET_COGNITOUSER', cognitoUser)
      return Promise.resolve()
    })
  },
  confirmPassword({ commit, state }, { confirmationCode, newPassword }): Promise<any> {
    if (!state.cognitoUser) {
      return Promise.reject()
    }
    return state.cognitoAuth.confirmPassword(state.cognitoUser, confirmationCode, newPassword)
  },
  changePassword({ commit, state }, { oldPassword, newPassword }): Promise<any> {
    if (!state.cognitoUser) {
      return Promise.reject()
    }
    return state.cognitoAuth.changePassword(state.cognitoUser, oldPassword, newPassword)
  },
  esSearch({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/diff-us', 'GET', additionalParams, {})
      .then(result => {
        commit('SET_USDIFF_QUERY', { params, additionalParams })
        if (result['data']['hits']['total'] > 0) {
          commit(
            'SET_USDIFF_RESULT',
            result['data']['hits']['hits'].map(r => {
              const tmp = r['_source']
              tmp['checked'] = false
              return tmp
            })
          )
        } else {
          commit('SET_USDIFF_RESULT', [])
        }
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  esSearchAu({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/diff-au', 'GET', additionalParams, {})
      .then(result => {
        commit('SET_AUDIFF_QUERY', { params, additionalParams })
        if (result['data']['hits']['total'] > 0) {
          commit(
            'SET_AUDIFF_RESULT',
            result['data']['hits']['hits'].map(r => {
              // 2018.05.03 EAN以外を実装、EANはkey名が分かり次第追加
              const tmp = r['_source']['AU']['static']
              tmp['asin'] = r['_id']
              tmp['ean'] = r['_source']['ean']
              tmp['l1'] = r['_source']['AU']['dynamic']['new']['l1']
              tmp['l1_sa'] = r['_source']['JPAU']['new']['l1_sa']
              tmp['lowest'] = r['_source']['JP']['dynamic']['new']['l1']
              tmp['release_date'] = r['_source']['JP']['static']['release_date']
              tmp['sales_rank'] = r['_source']['AU']['dynamic']['sales_rank']
              tmp['checked'] = false
              return tmp
            })
          )
        } else {
          commit('SET_AUDIFF_RESULT', [])
        }
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  checkAll({ commit, state }, { checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.usdiffResult.length; ++i) {
        commit('SET_USDIFF_CHECKED', { asin_index: i, checked: checked })
      }
      resolve()
    })
  },
  checkOne({ commit, state }, { asin, checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.usdiffResult.length; ++i) {
        if (state.usdiffResult[i]['asin'] === asin) {
          commit('SET_USDIFF_CHECKED', { asin_index: i, checked: checked })
        }
      }
      resolve()
    })
  },
  checkAllAu({ commit, state }, { checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.audiffResult.length; ++i) {
        commit('SET_AUDIFF_CHECKED', { asin_index: i, checked: checked })
      }
      resolve()
    })
  },
  checkOneAu({ commit, state }, { asin, checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.audiffResult.length; ++i) {
        if (state.audiffResult[i]['asin'] === asin) {
          commit('SET_AUDIFF_CHECKED', { asin_index: i, checked: checked })
        }
      }
      resolve()
    })
  },
  getUserListFile({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/diff-users/report', 'GET', additionalParams, {})
      .then(result => {
        return Promise.resolve(result['data'])
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  listUsers({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/diff-users', 'GET', additionalParams, {})
      .then(result => {
        commit('SET_LIST_USERS_QUERY', { params, additionalParams })
        commit('SET_LIST_USERS_RESULT', result)
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  listGroupUsers({ commit, state }, { params, additionalParams }): Promise<any> {
    if (params === undefined) {
      params = state.listGroupUsersQuery.params
    }
    if (additionalParams === undefined) {
      additionalParams = state.listGroupUsersQuery.additionalParams
    }
    return state.cognitoAuth
      .invokeApi(params, '/diff-users', 'GET', additionalParams, {})
      .then(result => {
        commit('SET_LIST_GROUP_USERS_QUERY', { params, additionalParams })
        commit('SET_LIST_GROUP_USERS_RESULT', result)
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  modUser({ commit, state }, { params, additionalParams }): Promise<any> {
    const userid = params['userid']
    const action = additionalParams['queryParams']['action']
    let method = 'PUT'
    if (action === 'delete') {
      method = 'DELETE'
    }
    return state.cognitoAuth.invokeApi(params, '/diff-users/{userid}', method, additionalParams, {}).then(() => {
      commit('UPDATE_LIST_USERS_RESULT', { username: userid, action: action })
      return Promise.resolve()
    })
  },
  modUserAttribute({ commit, state }, { params, additionalParams }): Promise<any> {
    const userid = params['userid']
    const action = additionalParams['queryParams']['action']
    const method = 'PATCH'
    return state.cognitoAuth.invokeApi(params, '/diff-users/{userid}', method, additionalParams, {}).then(() => {
      return Promise.resolve()
    })
  },
  modUserGroup({ state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth.invokeApi(params, '/admin/user_groups', 'POST', additionalParams, {})
  },
  modUserGroupAndUpdateList({ commit, dispatch }, { params, additionalParams }): Promise<any> {
    const userid = params['userid']
    const action = additionalParams['queryParams']['action']
    const group = additionalParams['queryParams']['group_name']
    params['group'] = group
    return dispatch('modUserGroup', { params: params, additionalParams: additionalParams })
      .then(() => {
        if (action === 'delete') {
          commit('UPDATE_LIST_GROUP_USERS_RESULT', { username: userid, action: action, group: group })
        } else {
          dispatch('listGroupUsers', { params: undefined, additionalParams: undefined })
        }
        return Promise.resolve()
      })
      .catch(err => {
        // console.log(err)
        return Promise.reject(err)
      })
  },
  describeUser({ state }, { userid }): Promise<any> {
    const params = { userid: userid }
    return state.cognitoAuth
      .invokeApi(params, '/diff-users/{userid}', 'GET', {}, {})
      .then(result => {
        result.data['userid'] = userid
        return Promise.resolve(result)
      })
      .catch(err => {
        // console.log(err)
        return Promise.reject(err)
      })
  },
  diffResearch({ commit, state }, params): Promise<any> {
    return state.cognitoAuth.invokeApi({}, '/diff-research', 'POST', {}, params)
  },
  sdrSearch({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/fba-search', 'GET', additionalParams, {})
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  setSdrSetting({ commit, state }, { value }): Promise<any> {
    const attrs: { Name: string; Value: string }[] = []
    attrs.push({ Name: 'custom:sdrSetting', Value: value })
    return state.cognitoAuth.setAttributes(attrs)
  },
  getSdrSetting({ commit, state }): Promise<any> {
    return state.cognitoAuth.getAttributes(['custom:sdrSetting']).then(result => {
      return Promise.resolve(result[0].Value)
    })
  },
  getFbaUsItems({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/fba/item-list', 'GET', additionalParams, {})
      .then(result => {
        // console.log(result)
        commit('SET_LIST_FBA_ITEMS_QUERY', { params, additionalParams })
        commit(
          'SET_LIST_FBA_ITEMS_RESULT',
          result['data']['Items'].map(r => {
            r['checked'] = false
            return r
          })
        )
        // commit('SET_LIST_FBA_ITEMS_RESULT', []);
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  modFbaUsItems({ commit, state }, { action, skus, src, dst }): Promise<any> {
    return state.cognitoAuth
      .invokeApi({}, '/fba/item-list', action, { queryParams: { src: src, dst: dst } }, skus)
      .then(result => {
        commit('UPDATE_FBA_ITEMS_RESULT', { action, skus, result })
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  fbaUsCheckAll({ commit, state }, { checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.listFbaItemsResult.length; ++i) {
        commit('SET_FBAUS_CHECKED', { sku_index: i, checked: checked })
      }
      resolve()
    })
  },
  fbaUsCheckOne({ commit, state }, { sku, checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.listFbaItemsResult.length; ++i) {
        if (state.listFbaItemsResult[i]['sku'] === sku) {
          commit('SET_FBAUS_CHECKED', { sku_index: i, checked: checked })
        }
      }
      resolve()
    })
  },
  async getOrderItems({ commit, state }, { params, additionalParams }): Promise<any> {
    try {
      const result = await state.cognitoAuth.invokeApi(params, '/order', 'GET', additionalParams, {})
      commit('SET_LIST_ORDER_ITEMS_QUERY', { params, additionalParams })
      if ('items' in result['data']) {
        result['data']['items'].map(r => {
          r['checked'] = false
          return r
        })
        commit('SET_LIST_ORDER_ITEMS_RESULT', result['data'])
      }
      return Promise.resolve(result)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  orderCheckAll({ commit, state }, { checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.listOrderItemsResult['items'].length; ++i) {
        if (
          state.listOrderItemsResult['items'][i]['fulfillment_channel'] === 'MFN' &&
          ['Shipped', 'Unshipped', 'PartiallyShipped'].indexOf(
            state.listOrderItemsResult['items'][i]['order_status']
          ) >= 0
        ) {
          commit('SET_ORDER_CHECKED', { order_item_id_index: i, checked: checked })
        }
      }
      resolve()
    })
  },
  orderCheckOne({ commit, state }, { order_item_id, checked }): Promise<void> {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < state.listOrderItemsResult['items'].length; ++i) {
        if (state.listOrderItemsResult['items'][i]['order_item_id'] === order_item_id) {
          commit('SET_ORDER_CHECKED', { order_item_id_index: i, checked: checked })
        }
      }
      resolve()
    })
  },
  downloadOrderFile({ commit, state }, { items, shippingMethod }): Promise<any> {
    const body = { items: items, shippingMethod: shippingMethod }
    return state.cognitoAuth
      .invokeApi({}, '/order/itemlist', 'POST', {}, body)
      .then(result => {
        return Promise.resolve(result['data'])
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  downloadOrderFile3({ commit, state }, { items, shippingMethod3 }): Promise<any> {
    const body = { items: items, shippingMethod: shippingMethod3 }
    return state.cognitoAuth
      .invokeApi({}, '/order/itemlist', 'POST', {}, body)
      .then(result => {
        return Promise.resolve(result['data'])
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  setSamuraisInfo({ commit, state }, { id, name, email }): Promise<any> {
    const attrs: { Name: string; Value: string }[] = []
    attrs.push({ Name: 'custom:samurais_id', Value: id })
    attrs.push({ Name: 'custom:samurais_name', Value: name })
    attrs.push({ Name: 'custom:samurais_email', Value: email })
    // console.log(attrs)
    return state.cognitoAuth
      .setAttributes(attrs)
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(err => Promise.reject(err))
  },
  getSamuraisInfo({ commit, state }): Promise<any> {
    const attrs: string[] = []
    attrs.push('custom:samurais_id')
    attrs.push('custom:samurais_name')
    attrs.push('custom:samurais_email')
    return state.cognitoAuth
      .getAttributes(attrs)
      .then((ary: string[]) => {
        const ret = {}
        ary.forEach(pair => {
          ret[pair['Name']] = pair['Value']
        })
        return ret
      })
      .catch(() => '')
  },
  async setSellerInfo({ commit, state }, { action, country, sellerId = '-', authToken = '-' }): Promise<any> {
    const ret = await state.cognitoAuth.invokeApi(
      {},
      '/seller_info',
      action,
      {},
      {
        country: country,
        seller_id: sellerId,
        auth_token: authToken,
      }
    )
    if (ret.status != 200) return Promise.reject(ret.data)
    if (ret.status == 200 && ret.data != 'OK') {
      if (action == 'POST') {
        if (ret.data.indexOf('TransactionCanceledException') >= 0) {
          return Promise.reject(Error('duplicateSellerId'))
        } else {
          return Promise.reject(ret.data)
        }
      } else if (action == 'DELETE') {
        if (ret.data.indexOf('TransactionCanceledException') >= 0) {
          // 削除時は重複エラーでも続行。
        } else {
          return Promise.reject(ret.data)
        }
      } else {
        return Promise.reject(ret.data)
      }
    }

    const attrs: { Name: string; Value: string }[] = []
    if (country === 'US') {
      attrs.push({ Name: 'custom:seller_id', Value: sellerId })
      attrs.push({ Name: 'custom:auth_token', Value: authToken })
    } else {
      attrs.push({ Name: 'custom:seller_id_' + country.toLowerCase(), Value: sellerId })
      attrs.push({ Name: 'custom:auth_token_' + country.toLowerCase(), Value: authToken })
    }
    return state.cognitoAuth
      .setAttributes(attrs)
      .then(result => {
        return Promise.resolve(result)
      })
      .catch(err => Promise.reject(err))
  },
  getSellerInfo({ commit, state }, { country }): Promise<any> {
    const attrs: string[] = []
    if (country === 'US') {
      attrs.push('custom:seller_id')
      attrs.push('custom:auth_token')
    } else {
      attrs.push('custom:seller_id_' + country.toLowerCase())
      attrs.push('custom:auth_token_' + country.toLowerCase())
    }
    return state.cognitoAuth
      .getAttributes(attrs)
      .then((ary: string[]) => {
        const ret = {}
        ary.forEach(pair => {
          ret[pair['Name']] = pair['Value']
        })
        return ret
      })
      .catch(() => '')
  },
  setAddressExtensionAvailable({ commit, state }): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        commit('SET_ADDRESS_EXTENSION_AVAILABLE', true)
        resolve(null)
      } catch (err) {
        // console.log(err)
        reject(err)
      }
    })
  },
  isAvailableSeller({ commit, state }, { seller_id, auth_token, country }): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = { seller_id: seller_id, auth_token: auth_token, country: country }
      return state.cognitoAuth
        .invokeApi({}, '/account-health', 'POST', {}, body, true)
        .then(result => {
          resolve(result['data'])
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  checkAccountHealth({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/account-health', 'GET', additionalParams, {})
      .then(result => {
        // console.log(result)
        commit('SET_ACCOUNT_HEALTH_QUERY', { params, additionalParams })
        commit('SET_ACCOUNT_HEALTH_RESULT', result)
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  getMfnSummary({ state }, { src, dst }): Promise<any> {
    const params = { queryParams: { src: src, dst: dst } }
    return state.cognitoAuth.invokeApi({}, '/mfn/inventory/summary', 'GET', params, {}).then(result => {
      // console.log(result)
      return Promise.resolve(result)
    })
  },
  getMfnAuSettings({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/mfn/au/settings', 'GET', additionalParams, {})
      .then(result => {
        // console.log(result)
        commit('SET_LIST_MFN_SETTINGS_QUERY', { params, additionalParams })
        commit(
          'SET_LIST_MFN_SETTINGS_RESULT',
          result['data']['Items'].map(r => {
            r['checked'] = false
            return r
          })
        )
        // commit('SET_LIST_FBA_ITEMS_RESULT', []);
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  modMfnAuSettings({ commit, state }, { action, group_id, src, dst }): Promise<any> {
    const params = { queryParams: { src: src, dst: dst } }
    return state.cognitoAuth
      .invokeApi({}, '/mfn/au/settings', action, params, group_id)
      .then(result => {
        // console.log(result)
        commit('UPDATE_MFN_SETTINGS_RESULT', { action, group_id, result })
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  getMfnAsinUploadStatus({ commit, state }, { params, additionalParams }): Promise<any> {
    return state.cognitoAuth
      .invokeApi(params, '/mfn/au/asin/status', 'GET', additionalParams, {})
      .then(result => {
        // console.log(result)
        commit('SET_LIST_MFN_UPLOAD_STATUS_QUERY', { params, additionalParams })
        commit(
          'SET_LIST_MFN_UPLOAD_STATUS_RESULT',
          result['data']['Items'].map(r => {
            const tmp = r
            try {
              const check_result = JSON.parse(r['check_result'])
              Object.assign(tmp, check_result)
            } catch (err) {
              // empty
            }
            return tmp
          })
        )
        // commit('SET_LIST_FBA_ITEMS_RESULT', []);
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  uploadAsinFiles({ commit, state }, { action, asins, src, dst }): Promise<any> {
    // const body = { 'items': 'items', 'shippingMethod': 'epacket', 'cost_type': '19' };
    return state.cognitoAuth
      .invokeApi({}, '/mfn/au/asin/upload', action, { queryParams: { src: src, dst: dst } }, asins)
      .then(result => {
        // console.log('result: ' + result)
        commit('SET_MFN_ASIN_UPLOAD_RESULT', { action, asins, result })
        return Promise.resolve(result)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  downloadInventoryFile({ commit, state }, { src, dst }): Promise<any> {
    const params = { queryParams: { src: src, dst: dst } }
    return state.cognitoAuth
      .invokeApi({}, '/mfn/inventory', 'GET', params, {})
      .then(result => {
        return Promise.resolve(result['data'])
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
  addAsinsToBlackList({ state }, { country, asins }): Promise<any> {
    const postBody = { country: country, asins: asins }
    return state.cognitoAuth.invokeApi({}, '/admin/blacklist', 'PUT', {}, postBody)
  },
  addEansToAsins({ state }, { asins }): Promise<any> {
    const postBody = { asins: asins }
    return state.cognitoAuth.invokeApi({}, '/admin/ean', 'PUT', {}, postBody)
  },
  sendOrderInfo({ commit, state }, params): Promise<any> {
    return state.cognitoAuth.invokeApi({}, '/mfn/send-order-info', 'PUT', {}, params)
  },
  getSurcharge({ commit, state }): Promise<any> {
    return state.cognitoAuth
      .invokeApi({}, '/surcharge', 'GET', {}, {})
      .then(result => {
        const obj = {}
        // console.log(result['data'])
        result['data'].forEach(elm => {
          obj[elm['shippingType']] = elm
        })
        commit('SET_SURCHARGE', obj)
        return Promise.resolve(obj)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  },
}

export default actions
