













































import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import CountrySelectComponent from './country-select.vue'

@Component({
  components: { 'country-select': CountrySelectComponent },
})
export default class NavbarComponent extends Vue {
  @Getter('session') session
  @Getter('accountInfo') accountInfo

  loginLinks = [
    { title: 'About', name: 'about', roles: ['user'] },
    { title: '設定', name: 'settings', roles: ['user'] },
    { title: '管理者用', name: 'adminhome', roles: ['operator', 'admin'] },
  ]
  logoutLinks = [
    { title: 'ログイン', name: 'login' },
    { title: '新規登録', name: 'signup' },
    { title: 'パスワード忘れ', name: 'forgotpass' },
    { title: 'About', name: 'about' },
  ]

  get loggedIn(): boolean {
    // getterは解決できないとfunctionが返るようだ
    if (this.session === null || typeof this.session === 'function') {
      return false
    }
    return this.session.isValid()
  }
  filteredLoginLinks() {
    if (this.accountInfo == null) {
      return false
    }
    return this.loginLinks.filter(link => {
      return this.accountInfo['cognito:groups'].some(
        group => link.roles.indexOf(group) >= 0
      )
    })
  }
}
