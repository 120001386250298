import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginComponent from './components/login/login.vue'
import store from './store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/login', component: LoginComponent, name: 'login' },
    {
      path: '/error',
      component: () => import(/* webpackChunkName: "error" */ './components/error/error.vue'),
      name: 'error',
    },
    {
      path: '/home',
      component: () => import(/* webpackChunkName: "home" */ './components/home/home.vue'),
      alias: '/',
      name: 'home',
      meta: { auth: true, roles: ['user', 'userpp'] },
    },
    {
      path: '/about',
      component: () => import(/* webpackChunkName: "about" */ './components/about/about.vue'),
      name: 'about',
    },
    {
      path: '/policy',
      component: () => import(/* webpackChunkName: "policy" */ './components/about/policy.vue'),
      name: 'policy',
    },
    {
      path: '/terms',
      component: () => import(/* webpackChunkName: "terms" */ './components/about/terms.vue'),
      name: 'terms',
    },
    {
      path: '/tokushou',
      component: () => import(/* webpackChunkName: "tokushou" */ './components/about/tokushou.vue'),
      name: 'tokushou',
    },
    {
      path: '/logout',
      component: () => import(/* webpackChunkName: "logout" */ './components/logout/logout.vue'),
      name: 'logout',
      meta: { auth: true, roles: ['user', 'userpp'] },
    },
    {
      path: '/settings',
      component: () => import(/* webpackChunkName: "setting" */ './components/setting/setting.vue'),
      name: 'settings',
      meta: { auth: true, roles: ['user', 'userpp'] },
    },
    {
      path: '/signup',
      component: () => import(/* webpackChunkName: "signup" */ './components/signup/signup.vue'),
      name: 'signup',
    },
    {
      path: '/forgotpass',
      component: () => import(/* webpackChunkName: "forgotpass" */ './components/forgotpass/forgotpass.vue'),
      name: 'forgotpass',
    },
    {
      path: '/adminhome',
      component: () => import(/* webpackChunkName: "adminhome" */ './components/adminhome/adminhome.vue'),
      name: 'adminhome',
      meta: { auth: true, roles: ['operator', 'admin'] },
    },
    {
      path: '/countries/:countries',
      component: () => import(/*webpackChunkName: "countries" */ './components/countries/countries.vue'),
      children: [
        // {
        //   path: 'price-diff',
        //   alias: '',
        //   component: () => import(/* webpackChunkName: "price-diff" */ './components/price-diff/price-diff.vue'),
        //   name: 'price-diff',
        //   meta: { auth: true, roles: ['user', 'userpp', 'DST'] }
        // },
        {
          path: 'research',
          component: () => import(/* webpackChunkName: "research" */ './components/research/research.vue'),
          name: 'research',
        //   alias: '',
          meta: { auth: true, roles: ['userpp', 'DST', 'DST_RO', 'DST_FBA'] },
        },
        {
          path: 'sdr',
          component: () => import(/* webpackChunkName: "sdr" */ './components/sdr/sdr.vue'),
          name: 'sdr',
          meta: { auth: true, roles: ['userpp', 'DST', 'DST_RO', 'DST_FBA'] },
        },
        {
          path: 'sdr2207',
          component: () => import(/* webpackChunkName: "sdr2" */ './components/sdr2/sdr.vue'),
          name: 'sdr2207',
          meta: { auth: true, roles: ['DST_SDR2207'] },
        },
        {
          path: 'sdr2209',
          component: () => import(/* webpackChunkName: "sdr3" */ './components/sdr3/sdr.vue'),
          name: 'sdr2209',
          meta: { auth: true, roles: ['DST_SDR2209'] },
        },
        {
          path: 'fba',
          component: () => import(/* webpackChunkName: "fba" */ './components/fba/fba.vue'),
          name: 'fba',
          meta: { auth: true, roles: ['DST_FBA'] },
        },
        {
          path: 'order',
          component: () => import(/* webpackChunkName: "order" */ './components/order/order.vue'),
          name: 'order',
          meta: { auth: true, roles: ['DST', 'DST_C1', 'DST_FBA'] },
        },
        {
          path: 'mfn',
          component: () => import(/* webpackChunkName: "mfn" */ './components/mfn/mfn.vue'),
          name: 'mfn',
          meta: { auth: true, roles: ['DST', 'DST_C1'] },
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const valid_countries = ['JPUS', 'JPAU']
  if (to.matched.some(record => record.name === 'login')) {
    store
      .dispatch('getSession', {})
      .then(() => {
        if (store.getters['session'] != null && store.getters['session'].isValid()) {
          next({ name: 'home' })
        } else {
          next()
          return
        }
      })
      .catch(err => {
        // console.log('something error')
        next()
        return
      })
    return
  } else if (to.matched.some(record => record.meta.auth)) {
    store
      .dispatch('getSession', {})
      .then(() => {
        if (store.getters['session'] != null) {
          const groups = store.getters['accountInfo']['cognito:groups']
          let src = 'undefined'
          let dst = 'undefined'
          if (Object.keys(to.params).indexOf('countries') >= 0) {
            //不正な国指定を除外
            if (valid_countries.indexOf(to.params.countries) < 0) {
              // console.log('invalid countires')
              next({ name: 'login' })
              return
            }
            // cognito:groups と countriesをゆるくチェック(両国RO以上あればOK)
            src = to.params.countries.substr(0, 2)
            dst = to.params.countries.substr(2, 2)
            let priv_src: boolean =
              groups.indexOf(src) >= 0 ||
              groups.indexOf(src + '_RO') >= 0 ||
              groups.indexOf(src + '_C1') >= 0 ||
              groups.indexOf(src + '_FBA') >= 0
            let priv_dst: boolean =
              groups.indexOf(dst) >= 0 ||
              groups.indexOf(dst + '_RO') >= 0 ||
              groups.indexOf(dst + '_C1') >= 0 ||
              groups.indexOf(dst + '_FBA') >= 0

            //userだけでもUSは通す
            if (src == 'JP' && dst == 'US' && groups.indexOf('user') >= 0) {
              priv_src = true
              priv_dst = true
            }
            if (!priv_src || !priv_dst) {
              next({ name: 'error' })
              return
            }
          }

          // cognito:groups と to.meta.roles をチェック(指定権限がないとNG))
          const priv_role: boolean = to.meta?.roles.some(role => {
            let ret = groups.indexOf(role.replace('DST', dst).replace('SRC', src)) >= 0
            if (dst == 'US') {
              //us_fbaだけ特別対応
              if (role === 'DST_FBA' && groups.indexOf('us_fba') >= 0) {
                ret = true
              }
            }
            return ret
          })
          if (!priv_role) {
            if (to.path.length > '/countries/JPUS/x'.length) {
              next({ path: to.path.substr(0, 16) })
            } else {
              next({ name: 'error' })
            }
            return
          }

          // routing ok
          next()
          return
        } else {
          // console.log('no session')
          next({ name: 'login' })
          return
        }
      })
      .catch(err => {
        // console.log('get session error')
        next({ name: 'login' })
        return
      })
    return
  } else {
    next()
    return
  }
})

export default router
