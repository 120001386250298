























import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import TableComponent from '@/components/table.vue'
import CountryMgr from '@/util/CountryMgr'

@Component({})
export default class CountrySelectComponent extends Vue {
  private options: Array<string> = CountryMgr.getAvailableCountryPairs()
  @Getter('accountInfo') accountInfo

  private active_countries = '----'
  @Watch('$route', { immediate: true }) onRouteChange(to, from) {
    this.init()
  }
  init() {
    let countries = '----'
    try {
      countries = this.countries.src.code + this.countries.dst.code
    } catch (_err) {
      try {
        countries = this.available_countries[0]
      } catch (_err2) {
        countries = 'JPUS'
      }
    }
    this.active_countries = countries
  }

  get countries() {
    if ('countries' in this.$route.params) {
      return CountryMgr.getCountryPair(this.$route.params.countries)
    } else {
      throw 'invalid countries'
    }
  }

  get available_countries() {
    const groups = this.accountInfo['cognito:groups']
    return this.options.filter(opt => {
      //グループ側は前半2文字の一致で判定する
      const src_match = groups.some(group => group.substr(0, 2) === opt.substr(0, 2))
      const dst_match = groups.some(group => group.substr(0, 2) === opt.substr(2, 2))
      return src_match && dst_match
    })
  }
  get unselected_options() {
    return this.available_countries.filter((option: string) => option !== this.active_countries)
  }
  onItemClick(event: MouseEvent) {
    const target: Element = event
      .composedPath()
      .filter(obj => (obj as Element).className === 'dropdown-item')[0] as Element
    const clicked_country_pair = target.getAttribute('data-value')
    let follow_path = '/'
    if ('countries' in this.$route.params) {
      follow_path = this.$route.fullPath.substr('/countries/xxxx'.length)
    }
    this.$router.push('/countries/' + clicked_country_pair + follow_path)
    return
  }
}
